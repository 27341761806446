import React, { useEffect } from 'react';

const VideoPlayer = ({ videoLink, playerWidth, playerHeight, borderRadius }) => {
  const videoRef = React.useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.removeAttribute('src');
      videoRef.current.load();
    }
  }, []);

  return (
    <video
      ref={videoRef}
      width={playerWidth}
      height={playerHeight}
      style={{ objectFit: 'cover', borderRadius: `${borderRadius}px` }}
      autoPlay
      controls>
      <source src={'https://potok.dance/api' + `${videoLink}`} type="video/mp4"></source>
    </video>
  );
};

export default VideoPlayer;
